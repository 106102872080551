.u-center-text {
  text-align: center !important;
}

.u-no-margin-bottom {
  margin-bottom: 0 !important;
}
.a {
  color: #a2213b;
}
.u-margin-bottom-big {
  margin-bottom: 9.8rem !important;
}

.u-margin-bottom-extra-big {
  margin-bottom: 12.8rem !important;
}

.u-margin-bottom-md {
  margin-bottom: 8rem !important;
}

.u-margin-bottom-ultra-sm {
  margin-bottom: 3.2rem !important;
}

.u-margin-bottom-sm {
  margin-bottom: 4.8rem !important;
}

.u-margin-bottom-sm--bigger {
  margin-bottom: 6.4rem !important;
}

.u-margin-bottom-xs {
  margin-bottom: 1rem !important;
}
