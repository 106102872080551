.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.mob-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2.4rem;

  &__link {
    &:link,
    &:visited {
      font-size: 2rem;
      color: #eee;
      font-weight: 500;
      letter-spacing: 1.7px;
      text-transform: uppercase;
    }

    &:hover,
    &:active {
    }

    &--active {
    }
  }
}
