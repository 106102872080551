.footer-nav {
  &__list {
    display: flex;
    align-items: center;
    gap: 4.8rem;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      padding-bottom: 1px;
      font-size: 2rem;
      color: #fff;
      border-bottom: 1px solid transparent;
      transition: var(--transition-fast);
    }

    &:hover,
    &:active {
      color: var(--color-main-tint-3);
      border-bottom: 1px solid currentColor;
    }
  }
}
