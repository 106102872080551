.btn {
  padding: 1.4rem 3.2rem;
  background-color: var(--color-main);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 2rem;
  transition: var(--transition-fast);

  &:hover {
    background-color: var(--color-main-shade-4);
  }
}
