.promo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4.8rem;
  row-gap: 1.6rem;
  width: 100%;
  background-color: var(--color-main-tint-2);
  padding: 1.4rem 3.2rem;
  flex-wrap: wrap;
  letter-spacing: 0.4px;

  @media only screen and (max-width: 31.25em) {
    justify-content: center;
  }

  &__text {
    font-size: 1.8rem;
    word-spacing: 0.3px;
    font-weight: 500;

    @media only screen and (max-width: 59em) {
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
      order: 1;
    }

    @media only screen and (max-width: 31.25em) {
      text-align: center;
      order: -1;
    }
  }
}
