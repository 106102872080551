.footer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 4.8rem;
  justify-content: space-between;
  background-color: var(--color-main-shade-2);
  padding: 3.6rem 8rem;
  color: #fff;

  &__copy {
    font-size: 2rem;
    text-align: center;
  }
}
