.text-box {
  padding: 2.4rem 3rem;
  background-color: rgba(255, 255, 255, 0.508);
  border-radius: 5px;
  box-shadow: 0 1rem 2rem 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: var(--transition-default);

  &:hover {
    transform: scale(1.01) translateY(-3px);
  }
}
