.slider {
  position: relative;
  height: 55rem;
  max-width: 90%;
  margin: 8rem auto 0;
  overflow-x: hidden;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.3);
  transition: all 0.3s;

  &__slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s;
  }

  &__img {
    width: 100%;
    transition: all 0.3s;
  }

  &__arr {
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
    z-index: 10;
    background-color: #fff;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 2.2rem;
    color: var(--color-main-shade-5);

    @media only screen and (max-width: 59em) {
      height: 4rem;
      width: 4rem;
      font-size: 2rem;
    }

    @media only screen and (max-width: 31.25em) {
      height: 3rem;
      width: 3rem;
      font-size: 1.6rem;
    }

    @media only screen and (max-width: 24.31em) {
      display: none;
    }

    &--left {
      left: 4rem;
    }

    &--right {
      right: 0;
    }

    &:hover {
      background-color: var(--color-main-tint-3);
    }
  }

  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3.2rem;
    row-gap: 1.2rem;
    position: absolute;
    bottom: 3.2rem;
    left: 50%;
    width: 30rem;
    flex-wrap: wrap;
    transform: translateX(-50%);
  }

  &__dot {
    background-color: transparent;
    border: 1px solid var(--color-main-tint-2);
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    cursor: pointer;

    @media only screen and (max-width: 59em) {
      height: 1.6rem;
      width: 1.6rem;
    }

    @media only screen and (max-width: 31.25em) {
      height: 1.2rem;
      width: 1.2rem;
    }

    &--active {
      background-color: #fff;
    }
  }

  @media only screen and (max-width: 59em) {
    height: 30rem;
  }

  @media only screen and (max-width: 46.37em) {
    height: 25rem;
  }

  @media only screen and (max-width: 31.25em) {
    height: 20rem;
  }
}
