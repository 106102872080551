.main-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 3.2rem;

  &__link {
    &:link,
    &:visited {
      font-size: 2rem;
      letter-spacing: 0.5px;
      font-weight: 500;
      color: inherit;
    }

    &:hover,
    &:active {
      color: var(--color-main-shade-4);
    }

    &--active {
      &:link,
      &:visited {
        color: var(--color-main-shade-5);
      }
    }
  }

  @media only screen and (max-width: 59em) {
    display: none;
  }
}
