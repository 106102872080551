.mob-nav-icon {
  display: none;
  height: 6rem;
  width: 6rem;
  cursor: pointer;

  @media only screen and (max-width: 59em) {
    display: inline-block;
  }
}
