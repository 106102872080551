.pages {
  &__center {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }

  &__contacts-grid {
    grid-template-columns: 1fr;
    gap: 12.8rem;
    max-width: 80%;
    margin: 0 auto;

    @media only screen and (max-width: 70.81em) {
      max-width: 90%;
    }
  }

  &__text {
    font-size: 1.75rem;
    letter-spacing: 0.5px;
    line-height: 1.8;
    width: 90%;
    margin: 0 auto;

    &--small {
      font-size: 1.6rem;
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 1.8rem;
    }

    &:last-of-type {
      margin-bottom: 2.4rem;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 20%;
    margin: 0 auto;
  }

  &__list-item {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    flex-basis: 50%;
  }

  &__list-arrow {
    color: var(--color-main);
  }

  &__about-grid {
    padding: 1.6rem 6rem;
    text-align: center;

    @media only screen and (max-width: 59em) {
      padding: 1.6rem 4.8rem;
    }

    @media only screen and (max-width: 46.37em) {
      padding: 1.6rem 2.4rem;
    }
  }

  &__rooms-grid {
    padding: 0 6rem;
  }

  &__faq-grid {
    padding: 0 6rem;
    margin-bottom: 8rem;
    align-items: start;
  }

  &__room-terms {
    gap: 2.4rem;
    padding: 0 6.4rem;
    margin-bottom: 6.4rem;

    @media only screen and (max-width: 24.31em) {
      padding: 0 2rem;
    }
  }
}
