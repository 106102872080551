.lang-button {
  position: absolute;
  top: 50%;
  right: 2rem;
  background-color: var(--color-main-shade-5);
  color: #fff;
  font-size: 1.6rem;
  padding: 7px 5px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 1px;

  &:focus-visible {
    outline-offset: 3px;
    outline-width: 3px;
  }

  &:hover {
    background-color: var(--color-main-shade-4);
  }

  @media only screen and (max-width: 59em) {
    top: 42%;
  }
}
