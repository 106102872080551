*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:root {
  /* Main Color */
  --color-main: #4477aa;
  --color-main-transparent: rgba(68, 119, 170, 0.61);

  --color-main-tint-1: #ecf1f7;
  --color-main-tint-2: #c7d6e6;
  --color-main-tint-3: #a2bbd5;
  --color-main-tint-4: #7ca0c4;
  --color-main-tint-5: #6992bb;

  --color-main-shade-1: #070c11;
  --color-main-shade-2: #142433;
  --color-main-shade-3: #223c55;
  --color-main-shade-4: #305377;
  --color-main-shade-5: #3d6b99;

  /* Secondary Color */
  --color-secondary: #ab4478;

  --color-secondary-tint-1: #eedae4;
  --color-secondary-tint-2: #ddb4c9;
  --color-secondary-tint-3: #cd8fae;
  --color-secondary-tint-4: #bc6993;
  --color-secondary-tint-5: #b35786;

  --color-secondary-shade-1: #11070c;
  --color-secondary-shade-2: #331424;
  --color-secondary-shade-3: #56223c;
  --color-secondary-shade-4: #783054;
  --color-secondary-shade-5: #9a3d6c;

  /* Grey Colors */
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-light-5: #eee;

  --color-grey-dark-1: #222;
  --color-grey-dark-2: #333;
  --color-grey-dark-3: #777;
  --color-grey-dark-4: #999;
  --color-grey-dark-5: #aaa;

  /* Link Defaults */
  --link-color-default: var(--color-main-shade-3);
  --link-color-hover: var(--color-main-tint-5);

  /* Button Defaults */
  --btn-background-color-default: var(--color-main-shade-5);
  --btn-padding-default: 1.4rem 3.2rem;
  --btn-padding-small: 1.2rem 2rem;
  --btn-padding-wide: 1.4rem 6rem;

  /* Transition Defaults */
  --transition-default: all 0.3s;
  --transition-slow: all 0.4s;
  --transition-fast: all 0.2s;

  /* Default font-sizes */
  --font-size-default: 1.6rem;

  --font-size-heading1-default: 4rem;

  --font-size-heading2-default: 5rem;

  --font-size-heading3-default: 2.6rem;

  --font-size-subheading-default: 1.8rem;
}

::selection {
  background-color: var(--color-main);
  color: #fff;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--color-main-shade-2);
  line-height: 1.7;
  background-image: url('./assets//images/beach/beach-8.jpg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;

  @media only screen and (max-width: 46.37em) {
    background-image: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input,
select,
option,
textarea {
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  padding: 0.7rem 1rem;
  border-radius: 4px;
}

button {
  background: transparent;
  border: none;
  padding: 0.7rem 1rem;
}

a {
  text-decoration: none;
}

.link:link,
.link:visited {
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 1px solid currentColor;
  color: inherit;
  transition: var(--transition-default);
}

.link:hover,
.link:active {
  color: var(--color-main-shade-4);
}

ul,
ol {
  list-style: none;
}

.flex {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.logo {
  width: 100%;
  height: 60rem;
  margin-bottom: -1rem;

  @media only screen and (max-width: 76.25em) {
    height: 40rem;
  }

  @media only screen and (max-width: 46.37em) {
    height: 25rem;
  }

  @media only screen and (max-width: 31.25em) {
    height: 20rem;
  }

  @media only screen and (max-width: 24.31em) {
    height: 17rem;
  }
}

.main {
  padding: 6rem 0 10rem;
  margin-bottom: auto;
}

.grid {
  display: grid;
  gap: 4.8rem;
}

.grid--1-col {
  grid-template-columns: repeat(1, 1fr);
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: 46.37em) {
    grid-template-columns: 1fr;
  }
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr) !important;

  @media only screen and (max-width: 70.81em) {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  @media only screen and (max-width: 46.37em) {
    grid-template-columns: 1fr !important;
  }
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.fs-md {
  font-size: 2.6rem !important;
}

#mob-nav-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(34, 60, 85, 0.833);
  z-index: 100000;
  transform: scale(0);
  transition: all 0.3s;
}

.mob-nav-modal-active {
  transform: scale(1) !important;
}

.px-sm {
  @media only screen and (max-width: 24.31em) {
    padding: {
      left: 2rem !important;
      right: 2rem !important;
    }
  }
}
