h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.2;
}

.heading {
  &__primary,
  &__secondary {
    font-weight: 700;
  }

  &__primary {
    font-size: 3.6rem;
    font-weight: 600;
    letter-spacing: 1.2px;
    color: var(--color-main);
    margin-bottom: 3.2rem;

    @media only screen and (max-width: 59em) {
      font-size: 3rem;
    }

    @media only screen and (max-width: 46.37em) {
      font-size: 2.6rem;
    }

    @media only screen and (max-width: 31.25em) {
      font-size: 2.2rem;
    }

    @media only screen and (max-width: 24.31em) {
      text-align: center !important;
    }
  }

  &__secondary {
    font-size: var(--font-size-heading2-default);
    font-weight: 600;
    letter-spacing: 1px;

    @media only screen and (max-width: 70.81em) {
      font-size: 4rem;
    }

    @media only screen and (max-width: 46.37em) {
      font-size: 3.4rem;
    }
  }

  &__tertiary {
    color: var(--color-main-shade-4);
    font-size: var(--font-size-heading3-default);
    margin-bottom: 1.5rem;
    line-height: 1.6;

    @media only screen and (max-width: 59em) {
      font-size: 2.4rem;
    }

    @media only screen and (max-width: 46.37em) {
      font-size: 2.2rem;
    }

    @media only screen and (max-width: 24.31em) {
      font-size: 1.8rem;
      word-wrap: break-word;
    }
  }
}

.align-author-center {
  @media only screen and (max-width: 24.31em) {
    text-align: center !important;
  }
}

.subheading {
  display: inline-block;
  color: var(--color-main-shade-4);
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--font-size-subheading-default);
}

.heading-quote {
  font-size: 8rem;
}

.about-textbox {
  .heading__tertiary {
    font-size: 3.2rem;
  }

  &:last-child {
    grid-column: 1 / -1;
    justify-self: center;
    margin-bottom: 6rem;
  }
}
