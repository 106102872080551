.container {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  min-height: 150rem;
  margin: 8rem auto;
  background-color: var(--color-grey-light-2);
  overflow-y: hidden;

  @media only screen and (max-width: 76.25em) {
    max-width: 90%;
  }

  @media only screen and (max-width: 59em) {
    max-width: 95%;
  }

  @media only screen and (max-width: 46.37em) {
    max-width: 100%;
    margin: 0 auto;
  }
}
