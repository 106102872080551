.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 0 0 3.2rem;
  border-bottom: 1px solid rgba(204, 204, 204, 0.996);

  @media only screen and (max-width: 59em) {
    padding-bottom: 2.2rem;
  }

  &__logo {
    width: 30rem;
    height: auto;
    border-radius: 5px;
  }

  &__nav-cont {
    width: 100%;
    position: relative;
  }
}
